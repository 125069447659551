import clsx from 'clsx';
import { type HTMLAttributes, forwardRef } from 'react';
import { Input, type InputProps } from '../inputs/Input';
import { useCombinedFieldProps } from './Field.helpers';
import type { FieldProps } from './Field.types';
import { FieldError } from './FieldError';
import { FieldInput } from './FieldInput';
import { FieldLabel } from './FieldLabel';
import { FieldWrapper } from './FieldWrapper';

export const FieldPrefix = ({ children }: { children: React.ReactNode }) => {
  return (
    <span className="field__prefix whitespace-nowrap shadow-sm font-bold rounded-md text-base flex items-center px-2.5 pr-5 -mr-2.5 bg-gray-50 text-gray-500">
      {children}
    </span>
  );
};

export const FieldSuffix = ({ children }: { children: React.ReactNode }) => {
  return (
    <span className="field__suffix whitespace-nowrap shadow-sm font-bold rounded-md text-base flex items-center px-2.5 pl-5 -ml-2.5 bg-gray-50 text-gray-500">
      {children}
    </span>
  );
};

export type FieldTextProps = FieldProps<InputProps> & {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  fieldTextProps?: HTMLAttributes<HTMLDivElement>;
};

export const FieldText = forwardRef<HTMLDivElement, FieldTextProps>(
  ({ inputComponent, prefix, suffix, fieldTextProps, ...props }, ref) => {
    const { wrapperProps, labelProps, inputProps, errorProps } = useCombinedFieldProps<InputProps>({
      type: 'text',
      fieldOptions: {
        validationBehavior: {
          initial: 'onSubmit',
          whenTouched: 'onSubmit',
        },
        ...props.fieldOptions,
      },
      ...props,
    });

    return (
      <FieldWrapper ref={ref} {...wrapperProps}>
        <FieldLabel {...labelProps} />

        <FieldInput
          type={inputProps.type}
          className={clsx(
            {
              'flex items-stretch relative': prefix || suffix,
              'field__input--with-prefix': prefix,
              'field__input--with-suffix': suffix,
            },
            fieldTextProps?.className,
          )}
          {...fieldTextProps}
        >
          {prefix && <FieldPrefix>{prefix}</FieldPrefix>}
          {!inputComponent && (
            <Input
              {...inputProps}
              disabled={inputProps.disabled}
              className={clsx(inputProps.className, {
                'z-10': prefix || suffix,
                'rounded-l-none': prefix,
                'rounded-r-none': suffix,
                'opacity-50': inputProps.disabled,
                'cursor-not-allowed': inputProps.disabled,
              })}
            />
          )}
          {!!inputComponent && inputComponent(inputProps)}
          {suffix && <FieldSuffix>{suffix}</FieldSuffix>}
        </FieldInput>

        <FieldError {...errorProps} />
      </FieldWrapper>
    );
  },
);
